const appConfig = {
  servicePrefix: {
    local: {
      auth: "http://localhost:500",
    },
    production: {
      auth: "https://api.libtrogen.com/auth",
    },
  },
  authenticatedEntryPath: "/media",
  unAuthenticatedEntryPath: "/media",
  tourPath: "/",
  locale: "en",
  enableMock: false,
  stage: "local",
};

export default appConfig;
